:root {
  --primary-color: #1e3957;
  --secondary-color: #a5711a;
  --off-white: #f9f9f9;
}

.sidenav-container {
  border-right: 1px solid black;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
  padding-top: 10%;
}

.link-button {
  width: 167px;
  height: 49px;
  background-size: 100% 100%;
  background-color: white;
  outline: none;
  border: none;
  margin-right: 20px;
  cursor: pointer;
}

.sidenav-p {
  font-size: 1.55vw;
  color: #707070;
  font-family: 'Open Sans';
}

.path-selected {
  color: #3298cb;
}

.signout {
  background-image: url('../assets/logout.png');
  background-size: 100% 100%;
  width: 39px;
  height: 47px;
  background-color: #fefefe;
  border: none;
  margin-left: 10px;
  outline: none;
  cursor: pointer;
}

.header {
  width: 100%;
  height: 80px;
  top: 0;
  z-index: 1000;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  border-bottom: 1px solid black;
}

.header-section {
  display: flex;
  align-items: center;
}

.header-select {
  font-family: sans-serif;
  border-radius: 4px;
  border-width: 0.6px;
  min-width: 200px;
  height: 40px;
  font-size: 17px;
}

.header-title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 120px;
  font-size: 50px;
  color: var(--primary-color);
}

.nav-list {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.nav-button {
  text-decoration: none;
  color: var(--primary-color);
  width: 100%;
}

.signin-btn {
  margin-top: 50px;
  color: var(--secondary-color);
}

.menu-icon-container {
  display: flex;
  justify-content: flex-end;
}

.LoginBox {
  width: 365px;
}

.blackout {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  background-color: black;
  opacity: 0.5;
}

.add-students-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  max-width: 800px;
  height: calc(100% - 75px);
  z-index: 1000;
  background-color: var(--off-white);
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(62, 62, 62, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px;
}

.modal-header {
  font-size: 20pt;
  margin-bottom: 20px;
}

.add-students-view {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
}

.add-modal-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 50px;
}

.add-student-manual {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-student-form {
  width: 100%;
  max-width: 450px;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  margin-top: 10px;
  padding: 25px;
  overflow: auto;
}

.label {
  margin-top: 10px;
}

.field {
  width: 100%;
}
