label {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
  width: 400px;
  line-height: 26px;
  margin-bottom: 10px;
  margin-left: 0;
}

input {
  padding: 15px;
}

.formBlock {
  margin-bottom: 100px;
  color: #3298cb;
}
.inputLabel {
  height: 20px;
  flex: 0 0 200px;
  margin-left: 10px;
}

#updateStatus {
  color: green;
  margin-left: 200px;
}
