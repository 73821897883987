.inputLabel {
  padding: 0;
  border-style: none;
  text-decoration: none;
}

.formBlock {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  margin-bottom: 4vh;
  color: #3298cb;
}

.billingRow {
  display: flex;
  flex-direction: row;
  align-items: end;
  margin-top: 3vh;
}

.rowPrefix {
}

#billingContainer {
  display: flex;
  flex-direction: column;
}

#updateStatus {
  color: green;
  margin-left: 200px;
}

#editBilling {
  text-decoration: none;
  border-style: none;
  background-color: transparent;
  color: #3298cb;
  text-align: right;
}

#editBilling:hover {
  cursor: pointer;
}

#billingContainer {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
}

#billingHeader {
  font-size: 200%;
  color: #3298cb;
  text-align: left;
}
