.signup-container {
  width: 100%;
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
}

/*
TODO: A lot of these style elements aren't used and should removed,
the file was originally a copy of another style sheet
*/

.signup-content {
  width: 35%;
  margin: auto;
  display: grid;
  column-gap: 20px;
  grid-template-columns: 50% 50%;
}

.signup-content input {
  width: 100%;
  height: 22px;
  margin-bottom: 20px;
}

.signup-content p {
  font-size: 16px;
  color: #3298cb;
  text-align: left;
}

.full-width {
  grid-column: 1 / 3;
}

.signup-btn {
  grid-column: 2;
  width: 100%;
  font-size: 20px;
}

.accountTypeOverlay {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 10%;
  padding-right: 20%;
  padding-top: 25%;
}
.accountTypePageList {
  text-align: left;
  list-style-type: circle;
}

.typeContainer {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin: 2%;
  min-width: 100px;
  max-width: 20%;
  height: 60vh;
  min-height: 200px;
  padding-left: 2%;
  padding-right: 2%;

  align-items: center;
  justify-content: space-evenly;

  font-size: 80%;
  border-style: solid;
  border-color: black;
  border-width: 1px;
}

.typeCostContainer {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: center;
  margin-top: 20%;
  margin-bottom: 20%;
}

.typeCost {
  display: flex;
  flex-direction: column;
  font-size: 300%;
  margin: 0;
  padding: 0;
  align-items: end;
  justify-content: end;
  line-height: 100%;
}

.typeMonth {
  margin: 0;
  padding: 0;
  text-align: end;
}

.typeButton {
  margin-left: 10%;
  margin-right: 10%;
  max-width: 80%;
  margin-bottom: 0;
  border: none;
}
